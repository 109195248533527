<!--
 * @Author: Han
 * @Date: 2021-12-15 17:53:43
 * @LastEditors: Han
 * @LastEditTime: 2021-12-29 17:32:15
 * @FilePath: \1\minepage\src\components\Link.vue
-->
<template>
    <div class="vvhan-link">
        <ul class="vvhan-drama-series">
            <li v-for="(itm,idx) in $store.state.LinkUrls" @click="goUrl(itm.key)" :key="itm.key"
                :style="{background:$store.state.COLORS[$store.state.COLORNUM[idx]],color:'#fff'}">{{itm.name}}
            </li>
        </ul>
    </div>
</template>

<script>
    export default {
        methods: {
            goUrl(e) {
                this.$emit('setShow')
                if (this.$route.params.videoName == e) {
                    return 0
                } else {
                    this.$store.commit('setKey', e)
                    this.$router.push({
                        name: 'Home',
                        params: {
                            videoName: e
                        }
                    })
                }
            }
        }
    }
</script>

<style lang="less" scoped>
    ::-webkit-scrollbar {
        width: 0;
    }

    .vvhan-link {
        overflow-y: scroll;
        padding: 16px;
        font-size: 14px;

        .vvhan-drama-series {
            display: flex;
            justify-content: center;
            list-style: none;
            flex-wrap: wrap;

            li {
                margin: 6px;
                padding: 0 6px;
                box-sizing: border-box;
                height: 36px;
                line-height: 36px;
                background: #fff;
                text-align: center;
                transition: all 0.3s;
                cursor: pointer;
                overflow: hidden;

                &:hover {
                    background-color: black !important;
                    color: #fff !important;
                    border-color: #000 !important;
                }
            }
        }
    }

    @media (max-width: 768px) {
        .vvhan-link {
            .vvhan-drama-series {
                ul {
                    grid-template-columns: repeat(1, 1fr);
                }
            }
        }
    }
</style>